jQuery(document).ready(function($) {
    $('.service_block__list ul li').each(function() {
        $(this).on('click', function() {
            $('.service_block__list ul li').removeClass('active');
            $(this).addClass('active');

            const index = $(this).data('index');
            const activeElement = $(`.service_block__item.item-${index}`);

            const cssShow = {
                top: "0%",
                transition: "all .5s",
            };
            const cssHide = {
                top: "100%",
                transition: "all .5s",
            };

            $('.service_block__item').removeClass('active');

            activeElement.css(cssShow);
            activeElement.prevUntil('.service_block__item.item-1').each(function() {
                $(this).css(cssShow);
            });

            activeElement.nextAll().each(function() {
                $(this).css(cssHide);
            });

            $(`.service_block__item.item-${index}`).addClass('active');
        })
    });
});