jQuery(document).ready(function ($) {

    if($('.image-slider').length > 0 ) {
        $('.image-slider').each(function () {
            var id = $(this).attr('id');
            $('.big-'+id).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: '.thumbs-'+id,
            })

            $('.thumbs-'+id).slick({
                slidesToShow: 5,
                slidesToScroll: 1,
                arrows: true,
                fade: false,
                asNavFor: '.big-'+id,
                dots: false,
                centerMode: false,
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ]
            })
        });

    }
});


