jQuery(document).ready(function($) {
    if ($('.milestones__years').length > 0) {
        $('.milestones__years').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.milestones__info',
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 1260,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

        $('.milestones__info').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.milestones__years',
            draggable: false
        })
    }
});