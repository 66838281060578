import Swiper, { Pagination } from 'swiper';
Swiper.use([Pagination]);

jQuery(document).ready(function($) {

    aboutSliderInit();

    let oldWidth = $(window).width();

    $( window ).resize(function() {
        let newWidth = $(window).width();

        if(newWidth > 767) {
            if(oldWidth < 768) {
                aboutSliderInit();
                oldWidth = newWidth;
            }
        } else {
            if(oldWidth > 767 ) {
                aboutSliderDestroy();
                oldWidth = newWidth;
            }
        }
    });



    function aboutSliderInit() {
        if($(window).width() > 767) {
            if ($('.about__slider--nav').length > 0) {
                $('.about__slider--nav').slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    asNavFor: '.about__slider',
                    dots: false,
                    focusOnSelect: true
                });

                $('.about__slider').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    fade: false,
                    asNavFor: '.about__slider--nav',
                    draggable: false
                })
            }
        }
    }

    function aboutSliderDestroy() {
        if ($('.about__slider--nav').length > 0) {
            $('.about__slider--nav').slick('unslick');
            $('.about__slider').slick('unslick')
        }
    }

});