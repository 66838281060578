/**
 * UpSolution Widget: w-search
 */
(function ($) {
    "use strict";

    $.fn.wSearch = function () {
        return this.each(function(){
            var $this = $(this),
                searchForm = $this.find('.w-search-form'),
                searchShow = $this.find('.w-search-show'),
                searchClose = $this.find('.w-search-close'),
                searchInput = searchForm.find('.w-search-input input'),
                searchOverlay = $this.find('.w-search-form-overlay'),
                $window = $(window),
                searchOverlayInitRadius = 25,
                $body = document.body || document.documentElement,
                $bodyStyle = $body.style,
                showHideTimer = null,
                searchHide = function(){
                    searchInput.blur();
                    searchForm.css({
                        '-webkit-transition': 'opacity 0.4s',
                        transition: 'opacity 0.4s'
                    });
                    window.setTimeout(function(){
                        searchOverlay
                            .removeClass('overlay-on')
                            .addClass('overlay-out')
                            .css({
                                "-webkit-transform": "scale(0.1)",
                                "transform": "scale(0.1)"
                            });
                        searchForm.css('opacity', 0);
                        clearTimeout(showHideTimer);
                        showHideTimer = window.setTimeout(function(){
                            searchForm.css('display', 'none');
                            searchOverlay.css('display', 'none');
                        }, 700);
                    }, 25);
                };

            // Handling virtual keyboards at touch devices
            if (jQuery.isMobile){
                searchInput
                    .on('focus', function(){
                        // Transforming hex to rgba
                        var originalColor = searchOverlay.css('background-color'),
                            overlayOpacity = searchOverlay.css('opacity'),
                            matches;
                        // RGB Format
                        if (matches = /^rgb\((\d+), (\d+), (\d+)\)$/.exec(originalColor)){
                            searchForm.css('background-color', "rgba("+parseInt(matches[1])+","+parseInt(matches[2])+","+parseInt(matches[3])+", "+overlayOpacity+")");
                        }
                        // Hex format
                        else if (matches = /^#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})$/.exec(originalColor)){
                            searchForm.css('background-color', "rgba("+parseInt(matches[1], 16)+","+parseInt(matches[2], 16)+","+parseInt(matches[3], 16)+", "+overlayOpacity+")");
                        }
                        // Fault tolerance
                        else {
                            searchForm.css('background-color', originalColor);
                        }
                        searchOverlay.addClass('mobilefocus');
                    })
                    .on('blur', function(){
                        searchOverlay.removeClass('mobilefocus');
                        searchForm.css('background-color', 'transparent');
                    });
            }

            searchShow.click(function(){

                var searchPos = searchShow.offset(),
                    searchWidth = searchShow.width(),
                    searchHeight = searchShow.height();
                // Preserving scroll position
                searchPos.top -= $window.scrollTop();
                searchPos.left -= $window.scrollLeft();
                var overlayX = searchPos.left+searchWidth/2,
                    overlayY = searchPos.top+searchHeight/2,
                    winWidth = $window.width(),
                    winHeight = $window.height(),
                    // Counting distance to the nearest screen corner
                    overlayRadius = Math.sqrt(Math.pow(Math.max(winWidth - overlayX, overlayX), 2) + Math.pow(Math.max(winHeight - overlayY, overlayY), 2)),
                    overlayScale = (overlayRadius+15)/searchOverlayInitRadius;

                searchOverlay.css({
                    width: searchOverlayInitRadius*2,
                    height: searchOverlayInitRadius*2,
                    left: overlayX,
                    top: overlayY,
                    "margin-left": -searchOverlayInitRadius,
                    "margin-top": -searchOverlayInitRadius
                });
                searchOverlay
                    .removeClass('overlay-out')
                    .show();
                searchForm.css({
                    opacity: 0,
                    display: 'block',
                    '-webkit-transition': 'opacity 0.4s 0.3s',
                    transition: 'opacity 0.4s 0.3s'
                });
                window.setTimeout(function(){
                    searchOverlay
                        .addClass('overlay-on')
                        .css({
                            "-webkit-transform": "scale(" + overlayScale + ")",
                            "transform": "scale(" + overlayScale + ")"
                        });
                    searchForm.css('opacity', 1);
                    clearInterval(showHideTimer);
                    showHideTimer = window.setTimeout(function() {
                        searchInput.focus();
                    }, 700);
                }, 25);
            });

            searchInput.keyup(function(e) {
                if (e.keyCode == 27) searchHide();
            });

            searchClose.on('click touchstart', searchHide);
        });
    };

    $(function(){
        jQuery('.header__nav .w-search').wSearch();
    });
})(jQuery);